import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { UserRole } from '../utils/constants';

interface IPermission {
  requiredRoles?: UserRole[];
  children: JSX.Element;
  Fallback?: FC;
}

export const hasPermission = (requiredRoles: IPermission['requiredRoles'], role: UserRole) => {
  if (!requiredRoles?.length) return true;
  if (!role) return false;

  return requiredRoles.some((requiredRole: UserRole) => role === requiredRole);
};

const Permission: FC<IPermission> = ({ children, requiredRoles, Fallback }) => {
  const role = useSelector((state: any) => state?.auth?.roles ?? []);

  const tokenEmpty = useSelector((state: any) => state?.auth?.token ?? null) === '';

  if (tokenEmpty || hasPermission(requiredRoles, role)) return children;

  return Fallback ? <Fallback /> : null;
};

export default Permission;
