import React, { useEffect } from 'react';
import { Button, Container, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import { ITheme } from '../../App';
import useAuth from '../../hooks/use-auth';
import { RootStore } from '../../redux/store';
import { useSelector } from 'react-redux';
import { loginValidationSchema } from '../../validation/validations';
import { getIsAuthorized } from '../../redux/auth/auth-reducer';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Logo } from '../../assets/logo.svg';

const useStyle = makeStyles((theme: ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.typical.white,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  form: {
    width: '100%',
    alignItems: 'center',
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    height: '3rem',
  },
  inputStyle: {
    width: '100%',
  },
  logo: {
    width: '170px',
    marginBottom: '25px',
  },

  submitButton: {
    background: '#7AF3CA !important',
    height: '2.5rem',
    width: '100%',
    margin: theme.spacing(6, 0, 2),
    '&:hover': {
      background: '#55AA8D !important',
    },
    '&:disabled': {
      background: '#CCCCCC !important',
    },
  },
  overrides:{
    MuiContainer : { root: { maxWidth: '400px !important'}},
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#43C099',
        },
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          '& input': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '& fieldset': {
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
            borderColor: '#BDBDBD !important',
          },
        },
        '&.MuiFormControl-root': {
          '& label.Mui-focused:not(.Mui-error)': {
            color: '#43C099',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#43C099',
          },
        },
        '& .MuiInputAdornment-root': {
          '&$disabled': {
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
          },
        },
        '&.MuiInputBase-root': {
          '& fieldset': {
            borderColor: '#BDBDBD',
          },
          '&.Mui-focused:not(.Mui-error) fieldset': {
            borderColor: '#43C099',
          },
          '&.Mui-focused label': {
            color: 'red',
          },
        },
        '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#000',
        },
      },
    },
  }
}));

export interface LoginSubmitProps {
  email: string;
  password: string;
}

const Login = () => {
  const classes = useStyle();
  const auth = useAuth();
  const history = useHistory();
  const { loading } = useSelector((state: RootStore) => state.auth);

  const isAuthorized = useSelector(getIsAuthorized);

  const handleLogin = (values: LoginSubmitProps) => {
    auth.signIn(values);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: handleLogin,
  });

  useEffect(() => {
    if (!isAuthorized) return;
    setTimeout(() => {
      history.push('/dashboard');
    }, );
  }, [isAuthorized]);

  return (
    <div className={classes.wrapper}>
      <Container maxWidth="xs">
        <Logo fill="#000" width="155" height="36" />
        <h3>Support Dashboard Login</h3>

        <form onSubmit={formik.handleSubmit} className={classes.form}>
          <TextField
            className={classes.inputStyle}
            variant="outlined"
            margin="normal"
            placeholder={'sso@wiley.com'}
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={formik.values.email}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
            onChange={formik.handleChange}
            inputProps={{ 'data-cypressid': 'login-email-input' }}
          />

          <TextField
            className={classes.inputStyle}
            variant="outlined"
            margin="normal"
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={formik.values.password}
            onChange={formik.handleChange}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
            inputProps={{ 'data-cypressid': 'login-password-input' }}
          />

          <Button
            className={classes.submitButton}
            type="submit"
            value={'Submit'}
            variant="contained"
            id={'login'}
            disabled={loading}
            data-cypressid="login-button"
          >
            Sign In
          </Button>
        </form>
      </Container>
    </div>
  );
};

export default Login;
