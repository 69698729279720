import React from 'react';
import JobList from '../job-view/job-list';
import { makeStyles } from '@mui/styles';
import { ITheme } from '../../App';
import AdhocOnixJob from '../adhoc-onix/adhoc-onix-job';

const useStyles = makeStyles((theme:ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.typical.white,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    marginTop: 0,
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  return (
    <>
      <div id='dashboard' className={classes.wrapper}>
        <AdhocOnixJob/>
        <JobList />
      </div>

    </>
  );
};

export default Dashboard;
