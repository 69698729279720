import axios, { AxiosRequestHeaders } from 'axios';
import qs from 'query-string';

export const REQUEST_PREFIX = '/api/v1';
axios.defaults.headers.post['Content-Type'] = 'application/json; charset=UTF-8'
axios.defaults.headers.get['Content-Type'] = 'application/json; charset=UTF-8'

const instance = axios.create({
  baseURL: REQUEST_PREFIX,
  responseType: 'json',
  withCredentials: true,
  paramsSerializer: params => qs.stringify(params),
});

export default instance;
