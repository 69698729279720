import instance from '../service/http';
import {v4 as uuidv4} from "uuid";


export const login = (data: any) => {
  return instance.post<any>('/authenticate', data, {headers: {  'Transaction-Id': uuidv4()}});
};

export const logout = () => {
  return instance.post<any>('/logout', {headers: {  'Transaction-Id': uuidv4()}});
};
