import React from 'react';
import TableHead from "@mui/material/TableHead";
import { ITableHead, ITableColumn } from './Interfaces';
import { columnTitlesToBeCentered } from './constants';
import TableRow from '@mui/material/TableRow';
import { TableCell, TableSortLabel } from '@mui/material';

const TableHeader = ({ classes, order, orderBy, onRequestSort, columns } : ITableHead) => (
  <TableHead>
    <TableRow className={classes.headerRow}>
      {columns.map(({ field, title, sorting = true, width, sortable, columnStyles }: ITableColumn) => {
        const commonsCellProps = {
          style: width ? { width: `${width}%` } : {},
          className: classes.headerCell,
          key: field,
          'data-cypressid': `${field}-header-cell`,
        };
        return ((onRequestSort && sorting) ? (
          <TableCell
            {...commonsCellProps}
            align={columnTitlesToBeCentered.includes(title) ? 'center' : 'left'}
            sortDirection={orderBy === field ? order : false}
          >
            {
              sortable
                ? (
                  <TableSortLabel
                    style={columnStyles?.tableSortLabel}
                    active={orderBy === field}
                    direction={orderBy === field ? order : 'asc'}
                    onClick={() => onRequestSort(field)}
                  >
                    {title}
                  </TableSortLabel>
                )
                : <>{title}</>
            }
          </TableCell>
        ) : (
          <TableCell {...commonsCellProps} align={columnTitlesToBeCentered.includes(title) ? 'center' : 'left'}>{title}</TableCell>));
      },
      )}
    </TableRow>
  </TableHead>
);

export default TableHeader;
