import instance from '../service/http';
import qs from "query-string";
import {v4 as uuidv4} from "uuid";

export const jobSearch = (data:any) => {
  const url = '/jobs?'+ qs.stringify(data);
  return instance.get<any>(url, {headers: {  'Transaction-Id': uuidv4()}});

};
export const paginationClicked = (url :string ) => {
  return instance.get<any>(url, { baseURL: '', headers: {  'Transaction-Id': uuidv4()}} );

};

export const jobPost = (data:any) => {
  const url = '/jobs';
  let paylod={
    modelType : "Job",
    type : "GlobalOnixAdHocJob",
    state : "wstate:Active",
    hasPayload:{
      ...data,
      isbns:data.isbns.split("\n")
    }
  }
  return instance.post<any>(url, paylod, {headers: {  'Transaction-Id': uuidv4()}});
};