import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Box } from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import { useTheme } from '@mui/material/styles';
import {labels} from "../../utils/constants";
import {formControlList} from "../../pages/job-view/job-list";

const PaginationActions = (props: any) => {
  const {
    backIconButtonProps,
    count,
    nextIconButtonProps,
    onPageChange,
    page,
    rowsPerPage,
    ...rest
  } = props;

  const theme = useTheme();

  const handleFirstPageButtonClick = (event: any) => {
    onPageChange(event, 0, labels.FIRST);
  };

  const handleBackButtonClick = (event: any) => {
    onPageChange(event, page - 1, labels.PREVIOUS);
  };

  const handleNextButtonClick = (event: any) => {
    onPageChange(event, page + 1, labels.NEXT);
  };

  const handleLastPageButtonClick = (event: any) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1), labels.LAST);
  };

  const totalPages = Math.ceil(count / rowsPerPage);
  if (totalPages <= 1) return null;
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
        // inputProps={{ 'data-cypressid': 'table-first-input' }}
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
        // inputProps={{ 'data-cypressid': 'table-back-input' }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
        // inputProps={{ 'data-cypressid': 'table-next-input' }}
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
        // inputProps={{ 'data-cypressid': 'table-last-input' }}
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
};

export default PaginationActions;
