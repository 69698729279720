import React, { FunctionComponent } from 'react';
import Header from '../components/header';
import Permission from './permission';
import { Redirect, Route, useLocation } from 'react-router-dom';
import InfoView from '../components/info-view';
import { IRouteProps, routes } from './routers';
import { useSelector } from 'react-redux';
import { getIsAuthorized } from '../redux/auth/auth-reducer';
import { makeStyles } from '@mui/styles';
import { ITheme } from '../App';

const useStyles = makeStyles((theme: ITheme) => ({
  wrapper: {
    backgroundColor: theme.palette.typical.white,
    width: '100%',
    zIndex:'700 !important'
    // display: 'flex'
  },
  workspace: {
    width: '100%',
    height: '80%',
    flexDirection: 'column',
    paddingTop: '50px',
    marginTop: '20px',
    display: 'flex',
  },
}));

const PrivateRoute: FunctionComponent<IRouteProps> = ({
  component: Component,
  requiredRoles,
  layoutProps,
  ...rest
}) => {
  const isTokenValid = useSelector(getIsAuthorized);
  const location = useLocation();
  const classes = useStyles();

  return (
    <>
      <div className={classes.wrapper}>
        <Header />
        <div id="workspace" className={classes.workspace}>
          <Permission
            requiredRoles={requiredRoles}
            Fallback={() => <InfoView cypressid="forbidden" title="403" text="Forbidden" />}
          >
            <Route
              {...rest}
              render={props =>
                isTokenValid ? (
                  <Component {...props} />
                ) : (
                  <Redirect to={{ pathname: routes.LOGIN.path, state: { from: location } }} />
                )
              }
            />
          </Permission>
        </div>
      </div>
    </>
  );
};

PrivateRoute.defaultProps = {
  path: '/',
};

export default PrivateRoute;
