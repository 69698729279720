import React, { useEffect, useState, useCallback, useRef } from 'react';
import cn from 'classnames';
import PaginationActions from './PaginationActions';
import { ITable, ITableColumn, ITableCell, ITableRow } from './Interfaces';
import useWindowSize from '../../hooks/use-windowSize';
import { NO_RESULTS_MESSAGE } from './constants';
import { ITheme } from '../../App';
import { makeStyles } from '@mui/styles';
import { CircularProgress, Paper, TablePagination, Typography } from '@mui/material';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableHeader from './header';
import { ApplicationDefaultConfig } from '../../config/application-default-config';

export const ACTIONS_KEY = 'table_actions';

interface TableCellProps {
  'data-dtrum-mask'?: boolean;
}

const useStyles = makeStyles((theme: ITheme) => ({
  root: {
    position: 'relative',
    flexGrow: 1,
  },
  loaderWrap: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex:'100 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  error: {
    width: '100%',
    padding: '3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

const usePaginationStyles = makeStyles((theme: ITheme) => ({
  root: {
    flexGrow: 1,
    visibility: ({ visible }: { visible: boolean }) => (visible ? 'visible' : 'hidden'),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
    marginRight: theme.spacing(0.5),
  },
  caption: {
    marginRight: theme.spacing(3),
  },
  toolbar: {
    paddingRight: '0',
    minHeight: '58px',
  },
}));

const useTableStyles = makeStyles((theme: ITheme) => ({
  tableContainer: (props: { tableContainerHeight: number; classes?: object }) => ({
    maxHeight: props.tableContainerHeight,
  }),
  table: {
    tableLayout: 'fixed',
  },
  tbody: {},
  headerRow: {
    backgroundColor: theme.palette.grey[100],
  },
  headerCell: {
    padding: theme.spacing(2, 3),
  },
  row: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.grey[50],
    },
  },
  rowCell: {
    padding: theme.spacing(2, 3),
  },
  clickableRow: {
    cursor: 'pointer',
  },
  tableTopWrapper: {
    display: 'flex',
  },
  additionalText: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 3),
    textTransform: 'uppercase',
    position: 'absolute',
    bottom: '100%',
    transform: 'translate(0, 50%)',
    zIndex: 1,
  },
}));

const DefaultCellRender = ({ value }: ITableCell) => <span>{value}</span>;
const LinkCellRender = ({ value }: ITableCell) => (
  <div>
    <a href={value} target="_blank">
      {value}
    </a>
  </div>
);

const sliceItems = (items: any, currentPage: number, rowsPerPage: number) =>
  items.slice(currentPage * rowsPerPage, currentPage * rowsPerPage + rowsPerPage);

const DataTable = ({
  cypressid,
  columns,
  items,
  error,
  totalItemsCount = items.length,
  autoPage = false,
  topPaginationVisibility = false,
  topPaginationDisplay = true,
  isLoading = false,
  showPagination = true,
  currentPage = 0,
  rowsPerPage,
  rowsPerPageOptions = [5, 10, 15],
  classes: classesOverride,
  sortField = '',
  sortDir,
  dataType,
  onOrderChange,
  onRowClick,
  onChangePage,
  onChangeRowsPerPage,
  additionalTopText,
}: ITable) => {
  const [, windowHeight] = useWindowSize();
  const [lastWindowHeight, setLastWindowHeight] = useState(windowHeight);
  const [stats, setStats] = useState({ currentPage, rowsPerPage });
  const [tableContainerHeight, setTableContainerHeight] = useState(0);

  const emptyError = !isLoading && !items.length ? NO_RESULTS_MESSAGE : '';
  const errorMessage = error || emptyError;

  const classes = useStyles();
  const tableClasses = useTableStyles({ classes: classesOverride, tableContainerHeight });
  const paginationClasses = usePaginationStyles({ visible: !errorMessage && topPaginationVisibility });

  useEffect(() => {
    setStats({ currentPage, rowsPerPage });
  }, [currentPage, rowsPerPage]);

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    rowsPerPage = value;
    setStats({ currentPage, rowsPerPage });
    if (autoPage) {
      setStats({ currentPage: 0, rowsPerPage: value });
    }
    onChangeRowsPerPage(value);
  };

  const handleChangePage = (_: any, newPage: number, type?: string) => {
    setStats({ currentPage, rowsPerPage });
    if (autoPage) {
      setStats({ ...stats, currentPage: newPage });
    }
    onChangePage(newPage, type);
  };

  const rows: ITableRow[] = autoPage ? sliceItems(items, stats.currentPage, stats.rowsPerPage) : items;

  const onClick = useCallback(
    (row: ITableRow) => () => {
      if (onRowClick) onRowClick(row);
    },
    [onRowClick]
  );

  const componentRef = useRef<HTMLDivElement>(null);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableTopWrapperRef = useRef<HTMLDivElement>(null);
  const tablePaginationRef = useRef<HTMLDivElement>(null);
  const errorMessageRef = useRef<HTMLDivElement>(null);
  const progressRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const componentHeight = componentRef?.current?.offsetHeight || 0;
    const tableTopWrapperHeight = tableTopWrapperRef?.current?.offsetHeight || 0;
    const tablePaginationHeight = tablePaginationRef?.current?.offsetHeight || 0;
    const errorMessageHeight = errorMessageRef?.current?.offsetHeight || 0;
    const progressHeight = progressRef?.current?.offsetHeight || 0;

    const resultHeight =
      componentHeight - tableTopWrapperHeight - tablePaginationHeight - errorMessageHeight - progressHeight;
    if (resultHeight > 0) setTableContainerHeight(resultHeight);
  }, [
    tableTopWrapperRef.current,
    componentRef.current,
    tablePaginationRef.current,
    errorMessageRef.current,
    progressRef.current,
    items,
  ]);

  useEffect(() => {
    if (tableContainerRef?.current?.scrollTo) {
      tableContainerRef.current.scrollTo(0, 0);
    }
  }, [currentPage]);

  useEffect(() => {
    if (!windowHeight) return;
    if (windowHeight && lastWindowHeight) {
      const sizeDifferent = windowHeight - lastWindowHeight;
      setTableContainerHeight(tableContainerHeight + sizeDifferent);
    }
    setLastWindowHeight(windowHeight);
  }, [windowHeight]);

  return (
    <Paper
      data-cypressid={`${cypressid || 'component'}-table`}
      className={classes.root}
      elevation={0}
      ref={componentRef}
    >
      <div className={tableClasses.tableTopWrapper} ref={tableTopWrapperRef}>
        {additionalTopText && additionalTopText.value.length > 0 && (
          <Typography
            variant="body2"
            data-cypressid={additionalTopText.cypressid}
            className={tableClasses.additionalText}
          >
            {additionalTopText.value}
          </Typography>
        )}
        {topPaginationDisplay && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            count={totalItemsCount}
            rowsPerPage={stats.rowsPerPage}
            page={stats.currentPage}
            SelectProps={{
              inputProps: { 'aria-label': 'rows per page' },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            classes={paginationClasses}
            ActionsComponent={PaginationActions}
            component="div"
          />
        )}
      </div>
      <TableContainer className={tableClasses.tableContainer} ref={tableContainerRef}>
        <Table className={tableClasses.table} aria-labelledby="tableTitle" aria-label="enhanced table" stickyHeader>
          <TableHeader
            classes={tableClasses}
            order={sortDir}
            orderBy={sortField}
            onRequestSort={onOrderChange}
            columns={columns}
          />

          <TableBody className={tableClasses.tbody} data-cypressid="data-table-body">
            {rows.map((row: ITableRow, i: number) => (
              <TableRow
                className={cn(tableClasses.row, { [tableClasses.clickableRow]: !!onRowClick })}
                onClick={onClick(row)}
                data-cypressid={`${row.id || i}-${dataType}-row`}
                tabIndex={-1}
                key={row.id ? `${row.id}-${i}` : i}
                hover={true}
              >
                {columns.map((column: ITableColumn, j: number) => {
                  const { field, render, dataModifier } = column;
                  const key = `${row.id || j}-${field}-cell`;
                  const CellRender = column.isLink ? LinkCellRender : render || DefaultCellRender;
                  const rawValue = field === ACTIONS_KEY ? row : row[field];
                  const additionalProps: TableCellProps = {};
                  if (column?.ignoreTracking) {
                    additionalProps['data-dtrum-mask'] = true;
                  }
                  return (
                    <TableCell className={tableClasses.rowCell} key={key} data-cypressid={key} {...additionalProps}>
                      <CellRender id={row.id} row={row} value={dataModifier ? dataModifier(rawValue) : rawValue} />
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {errorMessage && (
        <div className={classes.error} ref={errorMessageRef}>
          <Typography align="center" variant="h6" data-cypressid="table-error">
            {errorMessage}
          </Typography>
        </div>
      )}
      {!errorMessage && showPagination && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          count={totalItemsCount}
          rowsPerPage={stats.rowsPerPage}
          page={stats.currentPage}
          classes={paginationClasses}
          ActionsComponent={PaginationActions}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          component="div"
          ref={tablePaginationRef}
        />
      )}
      {isLoading && (
        <div ref={progressRef} className={classes.loaderWrap}>
          <CircularProgress />
        </div>
      )}
    </Paper>
  );
};

export default DataTable;
