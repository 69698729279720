import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
} from '../redux/auth/auth-types';
import { LoginSubmitProps } from '../pages/login/login';
import { errorsByCodes } from '../utils/error-messages';
import { getToken, removeToken } from '../service/auth';
import { useToasts } from 'react-toast-notifications';
import { login, logout } from '../api/auth-api';
import { AxiosError } from 'axios';

const useAuth = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [email, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoggingOut, setIsLoggingOut] = useState<boolean>(false);
  const signIn = (data: LoginSubmitProps) => {
    dispatch({ type: LOGIN_REQUEST });
    setUsername(data.email);
    setPassword(data.password);
  };

  const signOut = () => {
    dispatch({ type: LOGOUT_REQUEST });
    setIsLoggingOut(prevCheck => !prevCheck);
  };

  useEffect(() => {
    if (email && password) {
      const data: LoginSubmitProps = {
        email: email,
        password: password,
      };
      login(data)
        .then(response => {
          addToast('Login Successfully', { appearance: 'success' });
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              id: response?.data?.id,
              email: response?.data?.email,
              roles: response?.data?.roles,
              token: getToken(),
            },
          });
        })
        .catch((error: AxiosError) => {
          const errorMessage = errorsByCodes(error.response!.status);
          dispatch({
            type: LOGIN_FAILED,
            payload: {
              errorMessage: errorMessage,
              errorCode: error.response!.status,
            },
          });
          addToast(errorMessage, { appearance: 'error' });
        })
        .finally(() => {
          setUsername('');
          setPassword('');
        });
    }
  }, [email, password]);

  useEffect(() => {
    if (isLoggingOut) {
      logout()
        .then(response => {
          removeToken();
          dispatch({
            type: LOGOUT_SUCCESS,
          });
          setUsername('');
        })
        .catch((error: AxiosError) => {
          const errorMessage = errorsByCodes(error.response!.status);
          dispatch({
            type: LOGOUT_FAILURE,
            errorMessage: errorMessage,
            errorCode: error.response!.status,
          });
          addToast(errorMessage, { appearance: 'error' });
        })
        .finally(() => {
          setIsLoggingOut(false);
          setUsername('');
          setPassword('');
        });
    }
  }, [isLoggingOut]);

  return { signIn, signOut };
};

export default useAuth;
