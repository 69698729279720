import {isArray, isObject, mapValues} from 'lodash';
import {DATE_RANGE, DROP_DOWN_TYPES, JOB_STATUS, JOB_TYPES, REGIONS} from './enums';
import {enumLabels} from './constants';

export const trimText = (field: any) => (typeof field === 'string' ? field.replace(/^\s+|\s+$/g, '') : field);
export const trimFields: any = (data: any) =>
  mapValues(data, element =>
    // eslint-disable-next-line no-nested-ternary
    isArray(element)
      ? element.map(value => (isObject(value) ? trimFields(value) : trimText(value)))
      : isObject(element)
      ? trimFields(element)
      : trimText(element)
  );

export const populateDropDowns = (type: DROP_DOWN_TYPES): any[] => {
  let dropDownList: any[] = [];
  switch (type) {
    case DROP_DOWN_TYPES.JOB_TYPES:
      Object.keys(JOB_TYPES).map(key => dropDownList.push({ key: key, value: (JOB_TYPES as any)[key], text: enumLabels[key] }));
      break;
    case DROP_DOWN_TYPES.JOB_STATUS:
      Object.keys(JOB_STATUS).map(key => dropDownList.push({ key: key, value: (JOB_STATUS as any)[key], text: enumLabels[key] }));
      break;
    case DROP_DOWN_TYPES.DATE_RANGE:
      Object.keys(DATE_RANGE).map(key => dropDownList.push({ key: key, value: (DATE_RANGE as any)[key], text: enumLabels[key] }));
      break;
    case DROP_DOWN_TYPES.REGIONS:
      Object.keys(REGIONS).map(key => dropDownList.push({ key: key, value: key, text: (REGIONS as any)[key] }));
      break;
  }
  return dropDownList;
};

export const getStateFilter = (value: string): any => {
  let stateString: any = '';
   if (value == JOB_STATUS.ALL) {
    stateString = undefined;
  } else {
    stateString =  value;
  }
  return stateString;
};

export const getStatus = (state: string): JOB_STATUS => {
  return (Object.keys(JOB_STATUS).find(x => x === state) as JOB_STATUS) || JOB_STATUS.ALL;
};

export const getUTCDate = (date: string | null) => {
  let refDate = new Date();
  switch (date) {
    case DATE_RANGE.ONE_DAY:
      refDate.setDate(refDate.getDate() - 1);
      break;
    case DATE_RANGE.ONE_WEEK:
      refDate.setDate(refDate.getDate() - 7);
      break;
      case DATE_RANGE.TWO_WEEKS:
      refDate.setDate(refDate.getDate() - 14);
      break;
    default:
      break;
  }
  const fromDate = `${refDate.getUTCFullYear()}-${(refDate.getUTCMonth()+ 1).toString().padStart(2, '0')}-${refDate
    .getUTCDate()
    .toString()
    .padStart(2, '0')}`;
  return fromDate;
};

export const formatDate = (dateValue: string): string => {
  let formattedDate: string = '';
  const date = new Date(dateValue);
  if (date) {
    formattedDate = `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date
      .getUTCDate()
      .toString()
      .padStart(2, '0')} ${date.getUTCHours().toString().padStart(2, '0')}:${date
      .getUTCMinutes()
      .toString()
      .padStart(2, '0')}:${date.getUTCSeconds().toString().padStart(2, '0')}`;
  }
  return formattedDate;
};

export const isValueEmpty = (value: string): boolean => {
  if (!value) return true;
  else if (value === '') return true;
  else return value === null;
};
