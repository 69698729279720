import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { ApplicationDefaultConfig } from '../../config/application-default-config';
import { ITable } from '../../components/table/Interfaces';
import { DATE_RANGE, DROP_DOWN_TYPES, JOB_STATUS, JOB_TYPES } from '../../utils/enums';
import Table from '../../components/table';
import { ITheme } from '../../App';
import { Button, FormControl, InputLabel, Select, TextField } from '@mui/material';
import { useFormik } from 'formik';
import useJob from '../../hooks/use-job';
import { formatDate, populateDropDowns } from '../../utils/common-util';
import { RootStore } from '../../redux/store';
import { enumLabels } from '../../utils/constants';
import * as JOB_REQUEST_TYPE from "../../redux/job/job-request-types";

export interface JobListProps {
  type: JOB_TYPES;
  state: JOB_STATUS;
  createdBy: string;
  dateRange: DATE_RANGE;
  rowsPerPage: number;
  currentPage: number;
}

export const tableConfig = [
  { field: 'id', title: 'Id' },
  { field: 'status', title: 'State' },
  { field: 'createdDate', title: 'Created' },
  { field: 'userName', title: 'Creator' },
  { field: 'modifiedDate', title: 'Modified' },
  { field: 'location', title: 'Location', isLink: true },
];

export const formControlList = [
  {
    type: 'SELECT',
    id: 'adhocOnixType',
    title: 'Type',
    class: '',
    options: populateDropDowns(DROP_DOWN_TYPES.JOB_TYPES),
    value: ApplicationDefaultConfig.jobType,
  },
  {
    type: 'SELECT',
    id: 'state',
    title: 'State',
    class: '',
    options: populateDropDowns(DROP_DOWN_TYPES.JOB_STATUS),
    value: ApplicationDefaultConfig.jobStatus,
  },
  {
    type: 'SELECT',
    id: 'dateRange',
    title: 'Created',
    class: '',
    options: populateDropDowns(DROP_DOWN_TYPES.DATE_RANGE),
    value: ApplicationDefaultConfig.jobDateRange,
  },
  { type: 'TEXT', id: 'creator', title: 'Creator', class: '', value: '' },
];

const useStyle = makeStyles((theme: ITheme) => ({
  table: {
    tableLayout: 'auto',
  },
  input: {
    width: '20rem',
  },
  contentHeader: {
    position: 'relative',
    zIndex: theme.zIndex.appBar,
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    paddingLeft: '15px',
    paddingRight: '15px',
    flexDirection: 'row',
    position: 'relative',
    gap: '50px',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    paddingLeft: '15px',
    paddingRight: '15px',
    flexDirection: 'column',
    '&.hidden': {
      display: 'none',
    },
  },
  filters: {
    display: 'flex',
    justifyContent: 'space-evenly',
    flexGrow: 1,
    padding: theme.spacing(3),
    alignItems: 'center',
  },
  submitButton: {
    background: '#7AF3CA !important',
    width: '100%',
    justifyContent: 'space-evenly',
    maxWidth: '100px',
    margin: theme.spacing(6, 0, 2),
    '&:hover': {
      background: '#55AA8D !important',
    },
    '&:disabled': {
      background: '#CCCCCC !important',
    },
  },
}));

export default function JobList() {
  const classes = useStyle();
  const job = useJob();
  const dispatch = useDispatch();

  const loading: boolean = useSelector((state: RootStore) => state.job.loading);
  const totalCount: number = useSelector((state: RootStore) => state.job.totalCount);
  const currentPage: number = useSelector((state: RootStore) => state.job.currentPage);
  const rowPerPage: number = useSelector((state: RootStore) => state.job.rowsPerPage);
  const tableItemsList: any[] = useSelector((state: RootStore) => state.job.items);
  const type: any = useSelector((state: RootStore) => state.job.type);
  const state: any = useSelector((state: RootStore) => state.job.state);
  const createdBy: any = useSelector((state: RootStore) => state.job.createdBy);
  const dateRange: any = useSelector((state: RootStore) => state.job.dateRange);

  const items = useMemo(
    () =>
        tableItemsList.map(item => ({
        ...item,
        location: item.hasPayload.fileLocation || '',
        userName: item.hasPayload.creatorEmail || '',
        createdDate:  formatDate(item.createdDate),
        modifiedDate:  formatDate(item.modifiedDate),
        status: enumLabels[item.state],
      })),
    [tableItemsList]
  );

  const handleJobSearch = (values: JobListProps) => {
    values.rowsPerPage = rowPerPage;
    job.onJobListSearch(values);
  };
  const handleTypeChange = (e: any) => {
    formik.values.type = e.target.value;
     job.onTypeChange(e.target.value);
  };

  const handleStatusChange = (e: any) => {
    formik.values.state = e.target.value;
     job.onStatusChange(e.target.value);
  };

  const handleDateChange = (e: any) => {
    formik.values.dateRange = e.target.value;
     job.onDateChange(e.target.value);
  };

  const handleCreatedChange = (e: any) => {
    formik.values.createdBy = e.target.value;
    job.onCreatorChange(e.target.value);
  };

  const handleChangePage = useCallback(
    (newPage: number, paginationType?: string) => {
      dispatch({
        type: JOB_REQUEST_TYPE.JOB_PAGINATION_CHANGED,
        payload: {
          currentPage: newPage, rowsPerPage: rowPerPage
        },
      });
    },
    [currentPage]
  );

  const handleChangeRowsPerPage = useCallback(
    (newRowsPerPage: number) => {
      dispatch({
        type: JOB_REQUEST_TYPE.JOB_PAGINATION_CHANGED,
        payload: {
          currentPage: currentPage, rowsPerPage: newRowsPerPage
        },
      });
    },
    [rowPerPage]
  );


  const tableProps: ITable = {
    cypressid: 'job-list',
    dataType: 'jobs',
    classes,
    currentPage,
    autoPage: true,
    topPaginationVisibility: true,
    showPagination: false,
    totalItemsCount: totalCount,
    rowsPerPage: rowPerPage,
    columns: tableConfig,
    items: items,
    isLoading: loading,
   onChangePage: handleChangePage,
    onChangeRowsPerPage: handleChangeRowsPerPage,
  };

  const formik = useFormik({
    initialValues: {
      currentPage: 0,
      type: ApplicationDefaultConfig.jobType,
      state: ApplicationDefaultConfig.jobStatus,
      createdBy: '',
      dateRange: ApplicationDefaultConfig.jobDateRange,
      rowsPerPage: rowPerPage,
      totalCount: 0,
    },
    onSubmit: handleJobSearch,
  });
  useEffect(() => {
    handleJobSearch(formik.initialValues);
  }, []);

  return (
    <>
      <div className={classes.contentHeader}>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <FormControl key={`${formControlList[0].id}-formControl`} className={classes.input}>
            <InputLabel id={`${formControlList[0].id}-label`}>{formControlList[0].title}</InputLabel>
            <Select
              key={`${formControlList[0].id}-input`}
              native
              value={formik.values.type}
              onChange={handleTypeChange}
              label="Native"
              inputProps={{ 'data-cypressid': `job-${formControlList[0].id}-input` }}
            >
              {formControlList[0].options?.map(option => (
                <option key={`${option.key}-input`} value={option.value}>
                  {option.text}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl key={`${formControlList[1].id}-formControl`} className={classes.input}>
            <InputLabel id={`${formControlList[1].id}-label`}>{formControlList[1].title}</InputLabel>
            <Select
              key={`${formControlList[1].id}-input`}
              native
              value={formik.values.state}
              onChange={handleStatusChange}
              label="Native"
              inputProps={{ 'data-cypressid': `job-${formControlList[1].id}-input` }}
            >
              {formControlList[1].options?.map(option => (
                <option key={`${option.key}-input`} value={option.value}>
                  {option.text}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl key={`${formControlList[2].id}-formControl`} className={classes.input}>
            <InputLabel id={`${formControlList[2].id}-label`}>{formControlList[2].title}</InputLabel>
            <Select
              key={`${formControlList[2].id}-input`}
              native
              value={formik.values.dateRange}
              onChange={handleDateChange}
              label="Native"
              inputProps={{ 'data-cypressid': `job-${formControlList[2].id}-input` }}
            >
              {formControlList[2].options?.map(option => (
                <option key={`${option.key.toString()}-input`} value={option.value}>
                  {option.text}
                </option>
              ))}
            </Select>
          </FormControl>
          <TextField
            key={`${formControlList[3].id}-input`}
            id={`${formControlList[3].id}-input`}
            label={formControlList[3].title}
            name={formControlList[3].title}
            value={formik.values.createdBy}
            onChange={handleCreatedChange}
            variant="outlined"
            type="search"
            inputProps={{ 'data-cypressid': `job-${formControlList[3].id}-input` }}
          />
          <Button
            className={classes.submitButton}
            type="submit"
            value={'Search'}
            variant="contained"
            id={'search'}
            data-cypressid="job-search-button"
          >
            Search
          </Button>
        </form>
      </div>
      <div className={classes.content}>
        <Table {...tableProps} />
      </div>
    </>
  );
}
