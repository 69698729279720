import { AppBar, Grid, MenuItem, MenuList, Toolbar, Typography } from '@mui/material';
import useAuth from '../hooks/use-auth';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../assets/logo-header.svg';
import React from 'react';
import { routes } from '../router/routers';
import { useHistory } from 'react-router-dom';
import { Logout } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { RootStore } from '../redux/store';
import Menu from './menu';

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#FFFFFF !important',
  },
  rightMenu: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
    marginTop: '15px',
  },
  content: {
    color: 'black',
    fontWeight: 'bold !important',
    fontSize: 'xx-large !imporant',
  },
}));

const Header = () => {
  const classes = useStyle();
  const auth = useAuth();
  const history = useHistory();
  const { email } = useSelector((state: RootStore) => state.auth);
  const logout = () => {
    auth.signOut();
  };

  return (
    <>
      <AppBar className={classes.root}>
        <Toolbar>
          <Logo fill="#000" width="100" height="40" onClick={() => history.push(routes.DASHBOARD.path as string)} />

          <Grid container justifyContent={'right'} classes={classes.rightMenu}>
            <Menu />
            <div className={classes.rightMenu}>
              <Typography variant={'body1'}>{email}</Typography>
            </div>

            <MenuList>
              <MenuItem onClick={logout}>
                <Logout></Logout>
                Logout
              </MenuItem>
            </MenuList>
          </Grid>
        </Toolbar>
      </AppBar>
    </>
  );
};
export default Header;
