import React from 'react';
import { makeStyles } from '@mui/styles';

const useStyle = makeStyles(() => ({
  root: {
    backgroundColor: '#F8F8F8',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    paddingTop: '15px',
    bottom: '0',
    height: '4%',
    maxHeight:'25px !important',
    width: '100%',
  },
  content: {
    display: 'block',
    height: '5%',
    width: '100%'
  },
  splitter: {
    marginLeft: '1.1rem',
    marginRight: '1.1rem',
  },
}));

const Footer = () => {
  const classes = useStyle();
  const currentYear = new Date().getFullYear();
  return (
    <footer className={classes.root}>
      <div className={classes.content}>
      &copy; Copyright 2021-{currentYear} by John Wiley & Sons, Inc.
      </div>
    </footer>
  );
};

export default Footer;
