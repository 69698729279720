import {
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  Modal,
  Select,
  TextField,
  InputAdornment,
  Tooltip, RadioGroup, Radio, FormControlLabel,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { ITheme } from '../../App';
import { handleCreateJobModal, postJob } from '../../redux/job/job-actions';
import { RootStore } from '../../redux/store';
import { populateDropDowns } from '../../utils/common-util';
import { DROP_DOWN_TYPES } from '../../utils/enums';
import InfoSharpIcon from '@mui/icons-material/InfoSharp';
import { jobPayloadValidation } from '../../validation/validations';

const useStyle = makeStyles((theme: ITheme) => ({
  input: {
    width: '20rem',
  },
  contentHeader: {
    position: 'relative',
    zIndex: theme.zIndex.appBar,
    marginBottom: '20px',
  },
  form: {
    display: 'flex',
    paddingLeft: '15px',
    paddingRight: '15px',
    flexDirection: 'row',
    position: 'relative',
    gap: '50px'
  },
  grid: {
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingBottom: 0,
    minWidth:'500px !important',
    overflowY: 'auto'
  },
  gridItem: {
    display: 'flex',
    '& > div': {
      width: '100%',
    },
  },
  footer: {
    display: 'flex',
    '& > div': {
      width: '100%',
    },
    '& > button': {
      margin: '0px 5px 0px 5px',
    },
    padding: '15px 0',
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    paddingLeft: '15px',
    paddingRight: '15px',
    flexDirection: 'column',
    '&.hidden': {
      display: 'none',
    },
  },
  button: {
    color: theme.palette.common.black,
    minWidth: '7rem',
    '& span': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  }
}));

const boxStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24
};


const regions = populateDropDowns(DROP_DOWN_TYPES.REGIONS);

export interface JobCreationProps {
  fileName: string;
  isbns: string[];
  location: string;
  creatorEmail: string;
  tagOption: string;
}

export default function AdhocOnixJob() {
  const dispatch = useDispatch();
  const { email } = useSelector((state: RootStore) => state.auth);
  const job = useSelector((state: RootStore) => state.job);
  const { addToast } = useToasts();

  const handleCreate = (values: JobCreationProps) => {
    dispatch(postJob({ ...values, creatorEmail: email }, addToast, formik.resetForm()));
  };

  const handleReset = (e: any) => {
    formik.handleReset(e);
    dispatch(handleCreateJobModal(false));
  };

  const formik = useFormik({
    initialValues: {
      fileName: '',
      isbns: [],
      location: 'US',
      creatorEmail: '',
      tagOption: 'short'
    },
    validationSchema: jobPayloadValidation,
    onSubmit: handleCreate,
  });

  useEffect(() => {
    console.log(job);
  }, [job]);

  const classes = useStyle();

  const setInfo = () => {
    return (
      <Tooltip title="Each line should contain one ISBN. Supports '-' separator.">
        <InputAdornment style={{ marginTop: '-200px', cursor: 'help' }} position="end">
          <InfoSharpIcon color="info" />
        </InputAdornment>
      </Tooltip>
    );
  };

  return (
    <>
      <Modal open={job.createJob} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={boxStyle} maxWidth="sm">
          <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 500 }} open={job.loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <form onSubmit={formik.handleSubmit} >
            <Grid container spacing={1} className={`${classes.grid} hideHorizontalScroll`} sx={{minWidth: '500px !important'}}>
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  placeholder={'globalOnix_file'}
                  id="fileName"
                  label="File Name"
                  name="fileName"
                  value={formik.values.fileName}
                  onChange={formik.handleChange}
                  autoComplete="fileName"
                  autoFocus
                  inputProps={{ 'data-cypressid': 'job-file-name-input' }}
                  error={formik.touched.fileName && Boolean(formik.errors.fileName)}
                  helperText={formik.touched.fileName && formik.errors.fileName}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <FormControl className={classes.input}>
                  <InputLabel id="location-label">Region</InputLabel>
                  <Select
                    labelId="location"
                    id="location"
                    native
                    value={formik.values.location}
                    label="Location"
                    name="location"
                    inputProps={{ 'data-cypressid': 'job-location-input' }}
                    onChange={formik.handleChange}
                  >
                    {regions.map(region => (
                      <option key={`${region.key.toString()}-input`} value={region.key}>
                        {region.text}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <TextField
                  multiline
                  rows={10}
                  variant="outlined"
                  margin="normal"
                  value={formik.values.isbns}
                  id="isbns"
                  label="ISBNs"
                  name="isbns"
                  autoComplete="isbns"
                  autoFocus
                  onChange={formik.handleChange}
                  inputProps={{ 'data-cypressid': 'job-isbns-input' }}
                  InputProps={{
                    endAdornment: setInfo(),
                  }}
                  error={formik.touched.isbns && Boolean(formik.errors.isbns)}
                  helperText={formik.touched.isbns && formik.errors.isbns}
                />
              </Grid>
              <Grid item xs={12} sm={12} className={classes.gridItem}>
                <FormControl className={classes.input}>
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label"
                      name="tag-options"
                              value={formik.values.tagOption}
                              onChange={formik.handleChange}
                  >
                    <FormControlLabel name="tagOption" value="long" control={<Radio />} label="Long Tag" />
                    <FormControlLabel name="tagOption" value="short" control={<Radio />} label="Short Tag (Default)" />
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.footer}>
                <Button
                  disabled={false}
                  disableElevation
                  className={classes.button}
                  variant="contained"
                  color="secondary"
                  data-cypressid="cancel-button"
                  onClick={e => handleReset(e)}
                >
                  Cancel
                </Button>
                <Button
                  disabled={false}
                  type="submit"
                  value={'Submit'}
                  disableElevation
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  data-cypressid="create-button"
                >
                  Create
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}
