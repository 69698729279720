export const TOKEN_KEY = 'WPP_AUTH_TOKEN';
export const TOTAL_COUNT_KEY = 'X-Total-Count';

export type UserRole = 'JobViewer' | 'JobManager' | 'UserManager ';

export const enumLabels: { [key: string]: any } = {
  ADHOC_ONIX: 'Adhoc ONIX',
  ALL: 'All',
  ACTIVE: 'In Progress',
  COMPLETE: 'Complete',
  FAILED: 'Failed',
  INACTIVE: 'Inactive',
  PARTIAL: 'Partial',
  ONE_DAY: 'One day ago',
  ONE_WEEK: 'One week ago',
  ONE_MONTH: 'Last Month',
  TWO_WEEKS: 'Two weeks ago',
};

export const labels= {
  FIRST: 'first',
  LAST: 'last',
  PREVIOUS: 'previous',
  NEXT: 'next'
}
