import { FunctionComponent } from 'react';
import { RouteComponentProps, RouteProps } from 'react-router';
import Login from '../pages/login/login';
import NotFound from '../components/not-found';
import Dashboard from '../pages/dash-board/dashboard';

export interface IRouteProps extends RouteProps {
  isPrivate?: boolean;
  component: FunctionComponent<RouteComponentProps<{}>>;
  path: string;
  requiredRoles?: any;
  layoutProps?: any;
}

export const routes: { [key: string]: IRouteProps } = {
  LOGIN: {
    exact: true,
    path: '/login',
    component: Login,
  },
  DASHBOARD: {
    isPrivate: true,
    path: '/dashboard',
    component: Dashboard,
  },
  NOT_FOUND: {
    path: '*',
    component: NotFound,
  },
};
