import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILED,
  LOGOUT_SUCCESS,
  LOGOUT_REQUEST,
  LOGOUT_FAILURE,
} from './auth-types';
import { RootStore } from '../store';
import { isAuthorized } from '../../service/auth';

export interface AuthTypes {
  token: string;
  loading: boolean;
  error: boolean;
  errorMessage: any;
  id: string;
  email: string;
  errorCode: any;
  permissions: [];
  roles: [];
}

const INITIAL_STATE: AuthTypes = {
  token: '',
  loading: false,
  error: false,
  errorMessage: null,
  errorCode: null,
  id: '',
  email: '',
  permissions: [],
  roles: [],
};

type Action = { type: string; payload: any };

const auth = (state: AuthTypes = INITIAL_STATE, action: Action): AuthTypes => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        errorMessage: null,
        errorCode: null,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action?.payload?.token,
        roles: action?.payload?.roles,
        permissions: action?.payload?.permissions,
        id: action?.payload?.id,
        email: action?.payload?.email,
        loading: false,
        error: false,
        errorMessage: null,
        errorCode: null,
      };
    case LOGIN_FAILED:
      return {
        ...state,
        token: '',
        id: '',
        loading: false,
        error: true,
        errorMessage: action?.payload?.errorMessage,
        errorCode: action?.payload?.errorCode,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.payload?.errorMessage,
        errorCode: action?.payload?.errorCode,
      };

    default:
      return state;
  }
};

export const logout = (returnUrl = '/login') => ({ type: LOGOUT_REQUEST, payload: { returnUrl } });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutFailure = () => ({ type: LOGOUT_FAILURE });
export const getIsAuthorizing = (state: RootStore) => state.auth.loading;
export const getErrorMessage = (state: RootStore) => state.auth.errorMessage;
export const getReturnURL = (state: any) => state.auth.returnUrl;
const getToken = (state: RootStore): any => state.auth.token;
const getUserId = (state: RootStore): any => state.auth.id;

export const getIsAuthorized = (state: RootStore) => {
  const userId = getUserId(state);
  const token = getToken(state);
  return isAuthorized(token) && userId;
};

export default auth;
