import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { jobPost } from '../../api/job-api';
import { RootStore } from '../store';
import { HANDLE_CREATE_JOB_MODAL, JOB_POST_FAILED, JOB_POST_REQUEST, JOB_POST_SUCCESS } from './job-request-types';
import { errorsByCodes, invalidTokenMessage } from '../../utils/error-messages';
import { LOGOUT_FAILURE, LOGOUT_REQUEST, LOGOUT_SUCCESS } from '../auth/auth-types';
import { logout } from '../../api/auth-api';
import { removeToken } from '../../service/auth';
import { AxiosError } from 'axios';

const jobPostRequest = () => {
  return {
    type: JOB_POST_REQUEST,
  };
};

const jobPostSuccess = () => {
  return {
    type: JOB_POST_SUCCESS,
  };
};

const jobPostFailed = (error: any) => {
  return {
    type: JOB_POST_FAILED,
    payload: error,
  };
};

export const postJob =
  (jobPayload: object, toast: any, resetForm: any): ThunkAction<void, RootStore, unknown, AnyAction> =>
  async dispatch => {
    dispatch(jobPostRequest());
    await jobPost(jobPayload)
      .then(response => {
        console.trace(response);
        dispatch(jobPostSuccess());
        toast(`Successfully created a job: ${response.data.id}`, { appearance: 'success' });
        resetForm();
      })
      .catch(error => {
        const isInvalidToken = error.response!.data!.code === 'PE200';
        const message = isInvalidToken ? invalidTokenMessage : error.response.data.message;
        if (isInvalidToken) {
            dispatch(handleCreateJobModal(false));
          setTimeout(() => {
            dispatch({ type: LOGOUT_REQUEST });
            logout()
              .then(response => {
                removeToken();
                dispatch({
                  type: LOGOUT_SUCCESS,
                });
              })
              .catch((error: AxiosError) => {
                const errorMessage = errorsByCodes(error.response!.status);
                dispatch({
                  type: LOGOUT_FAILURE,
                  errorMessage: errorMessage,
                  errorCode: error.response!.status,
                });
              });
          }, 500);
        }
        dispatch(
          jobPostFailed({
            errorCode: error.response.data.code,
            errorMessage: message,
          })
        );
        toast(message, { appearance: 'error' });
      });
  };

export const handleCreateJobModal =
  (open: boolean): ThunkAction<void, RootStore, unknown, AnyAction> =>
  async dispatch => {
    dispatch({
      type: HANDLE_CREATE_JOB_MODAL,
      payload: open,
    });
  };
