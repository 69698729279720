import * as yup from 'yup';
import { emailRequired, enterValidEmail, fileNameIsNotOnCorrectFormat, filenameRequired, invalidISBNs, jobRequestEmptyIsbns, locationRequired, passwordRequired } from '../utils/error-messages';

const regx =/^([0-9A-Z][-]{0,1}){10,13}?$\b/;

export const loginValidationSchema = yup.object({
  email: yup.string().email(enterValidEmail).required(emailRequired),
  password: yup.string().required(passwordRequired),
});


export const jobPayloadValidation = yup.object({
  location: yup.string().required(locationRequired),
  fileName: yup.string().matches(/^[A-Za-z0-9_]+$/ , fileNameIsNotOnCorrectFormat).required(filenameRequired),
  isbns: yup.array()
      .transform(function(value, originalValue) {
        if (this.isType(value) && value !== null) {
          return value;
        }
        return originalValue ? originalValue.split('\n') : [];
      })
      .of(
        yup.string()
      ).ensure().min(1,jobRequestEmptyIsbns)
      .test('isbns-validation', invalidISBNs, (isbns)=> {
        let no_errors=true;
        isbns?.map((isbn) => {
         let isValid = regx.test(String(isbn));
          if(!isValid){
            no_errors=false
          } 
        })
        return no_errors;
      })
});