import React from 'react';
import { createTheme, Palette, Theme, ThemeOptions, ThemeProvider } from '@mui/material';
import Routes from './router';
import { ToastProvider } from 'react-toast-notifications';
import Footer from './components/footer';
import { makeStyles } from '@mui/styles';

interface IPalette extends Palette {
  typical: {
    main: string;
    accent: string;
    white: string;
    black: string;
    fieldset: string;
  };
  chip: {
    color: string;
    expandIcon: {
      background: string;
      color: string;
    };
  };
}

export interface ITheme extends Theme {
  palette: IPalette;
  [key: string]: any;
}
interface IThemeOptions extends ThemeOptions {
  palette: IPalette;
  [key: string]: any;
  overrides: any;
}

export const theme = createTheme({
  typography: {
    fontWeightMedium: 600,
    fontFamily: ['Open Sans', 'Arial', 'sans-serif'].join(','),
  },
  palette: {
    typical: {
      main: '#465151',
      accent: '#135AE1',
      white: '#FFFFFF',
      black: '#000000',
      fieldset: '##BDBDBD',
    },
    primary: {
      main: '#7AF3CA',
    },
    secondary: {
      main: '#43C099',
    },
    error: {
      main: '#D42C2C',
    },
    background: {
      default: '#F5F5F5',
    },
    chip: {
      color: '#C2C3C6',
      expandIcon: {
        background: '#808183',
        color: '#FFFFFF',
      },
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#43C099',
        },
        '&$disabled': {
          color: 'rgba(0, 0, 0, 0.54)',
        },
      },
    },
    MuiInputBase: {
      root: {
        '&$disabled': {
          '& input': {
            color: 'rgba(0, 0, 0, 0.54)',
          },
          '& fieldset': {
            color: 'rgba(0, 0, 0, 0.54)',
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
            borderColor: '#BDBDBD !important',
          },
        },
        '&.MuiFormControl-root': {
          '& label.Mui-focused:not(.Mui-error)': {
            color: '#43C099',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#43C099',
          },
        },
        '& .MuiInputAdornment-root': {
          '&$disabled': {
            cursor: 'default',
            background: 'rgba(0, 0, 0, 0.05)',
          },
        },
        '&.MuiInputBase-root': {
          '& fieldset': {
            borderColor: '#BDBDBD',
          },
          '&.Mui-focused:not(.Mui-error) fieldset': {
            borderColor: '#43C099',
          },
          '&.Mui-focused label': {
            color: 'red',
          },
        },
        '&:hover .MuiInputAdornment-root .MuiSvgIcon-root': {
          color: '#000',
        },
      },
    }
  },
} as IThemeOptions) as ITheme;

const useStyles = makeStyles({
  main: {
    overflow: 'hidden !important',
    display: 'flex',
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    '& .MuiTableSortLabel-icon': {
      display: 'none',
    },
    '& .MuiTableSortLabel-active .MuiTableSortLabel-icon': {
      display: 'inline-block',
      marginRight: 0,
      marginLeft: 2,
    },
  },
});

function App() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <main className={classes.main}>
        <ToastProvider newestOnTop={false} placement="top-right" autoDismiss={true} autoDismissTimeout={5000}>
          <Routes />
        </ToastProvider>
      </main>
      <Footer />
    </ThemeProvider>
  );
}

export default App;
