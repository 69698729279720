import React from 'react';
import { MemoryRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { routes } from './routers';
import PrivateRoute from './private-route';

const Routes = () => (
  <>
    <Router>
      <Switch>
        <Route {...routes.LOGIN} />
        <Route path="/" exact render={() => <Redirect to={routes.LOGIN.path} />} />
        <PrivateRoute {...routes.DASHBOARD} />
        <PrivateRoute {...routes.ADHOC_ONIX} />
        <Route {...routes.NOT_FOUND} />
      </Switch>
    </Router>
  </>
);

export default Routes;
