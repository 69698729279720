import React from 'react';
import { NavLink } from 'react-router-dom';
import menuConfig from './config';
import { makeStyles } from '@mui/styles';
import { ITheme } from '../../App';
import { SvgIconProps, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { RootStore } from '../../redux/store';
import { handleCreateJobModal } from '../../redux/job/job-actions';
import { ROLES } from '../../utils/enums';
import { isAuthorizedView } from '../../service/auth';

interface MenuConfigItem {
  id: string;
  title: string;
  href: string;
  exact?: boolean;
  icon: (props: SvgIconProps) => JSX.Element;
  authorizedRoles: ROLES[];
}

const useStyles = makeStyles((theme: ITheme) => ({
  list: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent:'left',
    padding: 0,
    margin: "auto auto",
    listStyle: 'none',
    '& > *': {
      marginLeft: `${theme.spacing(0.25)}rem`,
    },
    '& > *:first-child': {
      marginLeft: 0,
    },
  },
  item: {
    padding: '15px',
    cursor:'pointer',
    display: 'inline-flex',
    alignItems: 'center',
    // background: '#7AF3CA !important',
    justifyContent: 'center',
    // borderColor: '#616161',
    // boxShadow:'1px 1px 3px 1px #BDBDBD',
    // borderWidth: '2px',
    width: '100%',
    textDecoration: 'none',
    color: theme.palette.grey[900],
    borderRadius: '5px',
    '& *': {
      display: 'inline',
    },
    '&.active': {
      color: `${theme.palette.typical.accent} !important`,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}));

const Menu = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const auth = useSelector((state: RootStore) => state.auth);

  return (
    <ul className={classes.list}>
      {menuConfig.map(({ id, title, href, exact,icon: IconComponent, authorizedRoles }: MenuConfigItem) => {
        if(isAuthorizedView(auth.roles,authorizedRoles)){
          return (
            <li key={id}>
              <div className={classes.item} data-cypressid={`menu-button-${id}`} onClick={()=>{dispatch(handleCreateJobModal(true))}}>
                <IconComponent className={classes.icon} />
                <Typography>{title}</Typography>
              </div>
            </li>
          )
        }
      })}
    </ul>
  );
};
export default Menu;
