import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import {useEffect, useState} from 'react';
import { DATE_RANGE, JOB_STATUS, JOB_TYPES } from '../utils/enums';
import { JobListProps } from '../pages/job-view/job-list';
import { jobData } from '../redux/job/job-reducer';
import * as JOB_REQUEST_TYPE from '../redux/job/job-request-types';
import { jobSearch, paginationClicked } from '../api/job-api';
import { AxiosError } from 'axios';
import {dataTableErrorMessage, invalidTokenMessage} from '../utils/error-messages';
import { getStateFilter, getUTCDate, isValueEmpty } from '../utils/common-util';
import { ApplicationDefaultConfig } from '../config/application-default-config';
import { TOTAL_COUNT_KEY } from '../utils/constants';
import useAuth from "./use-auth";

const useJob = () => {
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const auth = useAuth();
  const [type, setType] = useState(ApplicationDefaultConfig.jobType);
  const [status, setStatus] = useState(ApplicationDefaultConfig.jobStatus);
  const [createdBy, setCreatedBy] = useState('');
  const [dateRange, setDateRange] = useState(ApplicationDefaultConfig.jobDateRange);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ApplicationDefaultConfig.rowsPerPage);
  const [totalCount, setTotalCount] = useState(0);
  const job: any = useSelector(jobData);

  const onTypeChange = (value: any) => {
    setType((JOB_TYPES as any)[value]);
  };

  const onStatusChange = (value: any) => {
    setStatus((JOB_STATUS as any)[value]);
  };

  const onDateChange = (value: any) => {
    setDateRange((DATE_RANGE as any)[value]);
  };

  const onCreatorChange = (value: any) => {
    setCreatedBy(value);
  };

  const onJobListSearch = (data: JobListProps) => {
    dispatch({ type: JOB_REQUEST_TYPE.JOB_SEARCH_REQUEST });
    setType(data.type);
    setStatus(data.state);
    setCreatedBy(data.createdBy);
    setCurrentPage(data.currentPage);
    setDateRange(data.dateRange);
    setRowsPerPage(data.rowsPerPage);
    jobSearchReq(data);
  };

  const onPaginationClick = (data: any) => {
    dispatch({ type: JOB_REQUEST_TYPE.JOB_SEARCH_REQUEST });
    setCurrentPage(data.currentPage);
    paginationClicked(data.url)
      .then(response => {
        if (response?.data?.items.length > 0){
          dispatch({
            type: JOB_REQUEST_TYPE.JOB_SEARCH_SUCCESS,
            payload: {
              type: type,
              currentPage: currentPage,
              rowsPerPage: rowsPerPage,
              totalCount: response.headers[TOTAL_COUNT_KEY] || response?.data?.items?.length,
              items: response?.data?.items,
              first: response?.data?._links?.first?.href,
              previous: response?.data?._links?.previous?.href,
              next: response?.data?._links?.next?.href,
              last: response?.data?._links?.last?.href,
            },
          });
        } else {
          dispatch({
            type: JOB_REQUEST_TYPE.JOB_SEARCH_SUCCESS,
            payload: {
              type: type,
              currentPage: 0,
              totalCount: 0,
              items: [],
              first: null,
              previous: null,
              next: null,
              last: null,
            },
          });
        }

      })
      .catch((error: AxiosError) => {
        dispatch({
          type: JOB_REQUEST_TYPE.JOB_SEARCH_FAILED,
          payload: {
            errorMessage: dataTableErrorMessage,
            errorCode: 404,
          },
        });
        addToast(dataTableErrorMessage, { appearance: 'error' });
      });
  };

  const jobSearchReq = (data: any) => {
    console.log('Search');
    const payload: any = {
      type: data.type,
      state: getStateFilter(data.state),
      createdLowerBound: getUTCDate(data.dateRange),
      size: ApplicationDefaultConfig.maxRecords,
      offset: data.currentPage * data.rowsPerPage,
    };
    if (!isValueEmpty(data.createdBy)) {
      payload.payloadKey = 'creatorEmail';
      payload.payloadKeyValue = data.createdBy;
    }
    jobSearch(payload)
      .then(response => {
        dispatch({
          type: JOB_REQUEST_TYPE.JOB_SEARCH_SUCCESS,
          payload: {
            type: data.type,
            state: data.status,
            createdBy: data.createdBy,
            dateRange: data.dateRange,
            rowsPerPage: data.rowsPerPage,
            currentPage: 0,
            totalCount: response?.data?.items?.length,
            items: response?.data?.items,
            first: response?.data?._links?.first?.href,
            previous: response?.data?._links?.previous?.href,
            next: response?.data?._links?.next?.href,
            last: response?.data?._links?.last?.href,
          },
        });
      })
      .catch((error: AxiosError) => {
        const isInvalidToken =  error.response!.data!.message.includes('PE200') || error.response!.data!.code === 'PE200';
        const errorMessage = isInvalidToken ? invalidTokenMessage : dataTableErrorMessage;
        if (isInvalidToken){
          setTimeout(() => {
            auth.signOut();
          }, 500);

        }
        dispatch({
          type: JOB_REQUEST_TYPE.JOB_SEARCH_FAILED,
          payload: {
            errorMessage: errorMessage,
            errorCode: error.response!.status,
          },
        });
        addToast(errorMessage, { appearance: 'error' });

      });
  };

  return { onJobListSearch, onTypeChange, onStatusChange, onDateChange, onPaginationClick, onCreatorChange };
};

export default useJob;
