import React, { FC } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

interface IInfoView {
  cypressid: string,
  title: string,
  text?: string,
  classes?: object,
}

const useStyles = makeStyles(theme => ({
  wrap: {
    padding: 2,
  },
  title: {
    textAlign: 'center',
  },
  text: {
    textAlign: 'center',
  },
}));

const InfoView: FC<IInfoView> = ({ cypressid, title, text, classes = {} }) => {
  const viewClasses = useStyles({ classes });
  return (
    <div className={viewClasses.wrap}>
      <Typography variant="h4" data-cypressid={`${cypressid}-title`} className={viewClasses.title}>
        {title}
      </Typography>
      {text && (
        <Typography variant="body1" data-cypressid={`${cypressid}-title`} className={viewClasses.text}>
          {text}
        </Typography>
      )}
    </div>
  );
};
export default InfoView;
