import { DATE_RANGE, JOB_STATUS, JOB_TYPES } from '../../utils/enums';
import { ApplicationDefaultConfig } from '../../config/application-default-config';
import { RootStore } from "../store";
import * as JOB_REQUEST_TYPE from "./job-request-types";



export interface JobTypes {
  loading: boolean;
  error: boolean;
  createJob: boolean;
  success: boolean;
  errorMessage: any;
  errorCode: any;
  type: JOB_TYPES;
  state: JOB_STATUS;
  createdBy: string;
  dateRange: DATE_RANGE;
  rowsPerPage: number;
  currentPage: number;
  totalCount: number;
  items: any[];
  first: string;
  previous: string;
  next: string;
  last: string;
}

const INITIAL_STATE: JobTypes = {
  loading: false,
  error: false,
  createJob: false,
  success: false,
  errorMessage: '',
  errorCode: null,
  createdBy: '',
  currentPage: 0,
  dateRange: ApplicationDefaultConfig.jobDateRange,
  rowsPerPage: ApplicationDefaultConfig.rowsPerPage,
  totalCount: 0,
  type: ApplicationDefaultConfig.jobType,
  state: ApplicationDefaultConfig.jobStatus,
  items: [],
  first: '',
  previous: '',
  next: '',
  last: ''
};

type Action = { type: string, payload: any }

const job = (state: JobTypes = INITIAL_STATE, action: Action): JobTypes => {
  switch (action.type) {
    case JOB_REQUEST_TYPE.JOB_SEARCH_REQUEST:
      return {
        ...state,
        currentPage:0,
        loading: true,
        error: false,
        errorMessage: null,
        errorCode: null,

      };
    case JOB_REQUEST_TYPE.JOB_SEARCH_SUCCESS:
      return {
        ...state,
        totalCount: action?.payload?.totalCount,
        items: action?.payload?.items,
        first: action?.payload?.first,
        previous: action?.payload?.previous,
        next: action?.payload?.next,
        last: action?.payload?.last,
        currentPage: action?.payload?.currentPage,
        rowsPerPage: action?.payload?.rowsPerPage,
        loading: false,
        error: false,
        errorMessage: null,
        errorCode: null,
      };
    case JOB_REQUEST_TYPE.JOB_SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        errorMessage: action?.payload?.errorMessage,
        errorCode: action?.payload?.errorCode,
      };
      case JOB_REQUEST_TYPE.JOB_PAGINATION_CHANGED:
      return {
        ...state,
        currentPage: action?.payload?.currentPage,
        rowsPerPage: action?.payload?.rowsPerPage,

      };
    case JOB_REQUEST_TYPE.JOB_POST_REQUEST:
      return {
        ...state,
        loading: true,
        error: false,
        success: false,
        errorMessage: null,
        errorCode: null,
      };
    case JOB_REQUEST_TYPE.JOB_POST_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        success: true,
        createJob:false,
        errorMessage: null,
        errorCode: null,
      };
    case JOB_REQUEST_TYPE.JOB_POST_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        success: false,
        errorMessage: action?.payload?.errorMessage,
        errorCode: action?.payload?.errorCode,
      };
    case JOB_REQUEST_TYPE.HANDLE_CREATE_JOB_MODAL:
      return {
        ...state,
        createJob: action?.payload
      }
    default:
      return state;
  }
};
export const jobData = (state: RootStore) => state.job;
export default job;


