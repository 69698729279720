import { useLayoutEffect, useState } from 'react';
import { isAuthorized } from '../service/auth';

const useWindowSize = () => {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight > 0 ? window.innerHeight-100 : 0]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [isAuthorized]);
  return size;
};

export default useWindowSize;
