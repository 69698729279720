import {FileOpenOutlined} from '@mui/icons-material';
import {ROLES} from '../../utils/enums';

export default [
  {
    id: 'ADHOC_ONIX',
    title: 'Adhoc Onix',
    href: "",
    icon: FileOpenOutlined,
    authorizedRoles: [ROLES.JOB_MANAGER,ROLES.DASHBOARD_ADMIN]
  }
];
