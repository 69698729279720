import { DATE_RANGE, JOB_STATUS, JOB_TYPES } from '../utils/enums';

export const ApplicationDefaultConfig = {
  rowsPerPage: 5,
  jobDateRange:DATE_RANGE.TWO_WEEKS,
  jobType:JOB_TYPES.ADHOC_ONIX,
  jobStatus:JOB_STATUS.COMPLETE,
  maxRecords: 100

}

