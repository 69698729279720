export const HTTP_ERROR_LOGIN_401 =
  'Invalid email or password. Please try again or contact your Wiley editorial colleague or the System Administrator.';
export const HTTP_ERROR_403 = 'Unauthorized activity. Please contact the System Administrator.';
export const HTTP_ERROR_423 = 'The account is locked. Please contact the System Administrator.';
export const HTTP_DEFAULT_ERROR_LOGIN = 'The user cannot be authenticated at this time. Please try again or contact the System Administrator.';

export const HTTP_ERROR_COMMON_401 = 'Your session has expired.';
export const HTTP_ERROR_COMMON_404 = 'Object not found. Please contact the System Administrator.';

export const emailRequired = 'Email is required';
export const enterValidEmail = 'Enter a valid email';
export const passwordRequired = 'Password is required';
export const dataTableErrorMessage='Data Retrieving Error'
export const invalidTokenMessage ='Invalid User Session, User will be logged out.'

export const locationRequired = 'Location is required.';
export const filenameRequired = 'Filename is required.';
export const fileNameIsNotOnCorrectFormat = 'Filename is not in correct format.';
export const jobRequestEmptyIsbns = 'Job request must contain at least one ISBN.'
export const invalidISBNs = 'ISBNs contain invalid data.';

type ErrorByCode = {
  [key: string]: (code?: number) => string;
};

type ErrorByStatus = {
  [key: number]: string;
};

export const errorsByCode: ErrorByCode = {
  FAILED_AUTHENTICATE_USER: (code?: number) => {
    switch (code) {
      case 401:
        return 'Invalid email or password. Please try again or contact your Wiley editorial colleague or the System Administrator.';
      case 403:
        return 'Unauthorized activity. Please contact the System Administrator.';
      case 423:
        return 'The account is locked. Please contact the System Administrator.';
      default:
        return 'The user cannot be authenticated at this time. Please try again or contact the System Administrator.';
    }
  },
};

export const errorsByCodes = (code?: number) => {
  switch (code) {
    case 401:
      return HTTP_ERROR_LOGIN_401;
    case 403:
      return HTTP_ERROR_403;
    case 423:
      return HTTP_ERROR_423;
    default:
      return HTTP_DEFAULT_ERROR_LOGIN;
  }
};

export const commonErrorsByStatus: ErrorByStatus = {
  401: HTTP_ERROR_COMMON_401,
  404: HTTP_ERROR_COMMON_404,
};
