export enum JOB_TYPES {
  ADHOC_ONIX = 'GlobalOnixAdHocJob'
}

export enum JOB_STATUS {
  ALL = 'All',
  ACTIVE = 'wstate:Active',
  COMPLETE = 'wstate:Complete',
  FAILED = 'wstate:Failed'
  // INACTIVE = 'wstate:Inactive',
  // PARTIAL = 'wstate:Partial'
}

export enum REGIONS {
  US = 'United States',
  Canada = 'Canada',
  UK = 'United Kingdom',
  Asia = 'Asia',
  Australia = 'Australia'
  // Germany = 'Germany'
}

export enum DATE_RANGE {
  // ALL = 'All',
  ONE_DAY = 'One day ago',
  ONE_WEEK = 'One week ago',
  TWO_WEEKS = '2 weeks ago'
  // ONE_MONTH = 'Last Month',
}

export enum DROP_DOWN_TYPES {
  DATE_RANGE,
  JOB_STATUS,
  JOB_TYPES,
  REGIONS
}


export enum ROLES {
  JOB_VIEWER = 'JobViewer',
  JOB_MANAGER = 'JobManager',
  DASHBOARD_ADMIN = 'UserManager'
}